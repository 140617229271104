<div class="partner-area pb-70" style="background-color: #482e0729;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-4 col-md-4" *ngFor="let Item of partnerItem;">
                <div class="single-partner-item">
                    <img [src]="Item.img" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="circle-shape1"><img src="assets/img/shape/circle-shape1.png" alt="image"></div>

    <div class="divider"></div>
</div>