<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">epigrafo</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<div class="container">

    <div class="row div-row">
        <p class="text">Sabemos da importância da segurança na utilização de dados pessoais de nossos usuários. Por
            isso, temos a preocupação em esclarecer e divulgar a nossa política de utilização dessas informações. Desta
            forma,
            você entenderá melhor quais informações são obtidas e como as mesmas podem ser utilizadas</p>
        <p class="text">Dedique alguns minutos do seu tempo para ler nossa Política de Privacidade e aproveitar da
            melhor forma todas
            as soluções virtuais que foram disponibilizamos pela empresa <b>epigrafo.com</b></p>
        <p class="text">Recomendamos a leitura periódica, pois a mesma pode ser atualizada periodicamente.</p>

    </div>

    <div class="row div-row">

        <h5 class="text-h5">Págrafo Primeiro – Sobre Informações Obtidas</h5>

        <p class="text">As informações são coletadas de diversas formas, em diferentes áreas das soluções virtuais
            oferecidas pela empresa <b>epigrafo.com</b>.</p>

        <p class="text">Algumas informações pessoais são obtidas quando os usuários se registram em seu website. Durante
            esse processo de registro, nossas soluções virtuais perguntam nome, endereço de e-mail e outras informações
            pessoais. Quanto mais informações corretas seus usuários fornecerem, melhor será a personalização da sua
            experiência. O formulário de cadastro pode ser personalizado por sua instituição ou pré-definido dentro de
            nossos padrões.</p>

        <p class="text">Esclarecemos que é tecnicamente impossível que haja como “login” quaisquer palavras, expressões
            ou conjuntos gráficos denominativos que já tenham sido escolhidos anteriormente por outros usuários e/ou
            assinantes dos serviços contratados em seu website; bem como é vedado a esses assinantes e/ou usuários a
            escolha de expressões malsoantes, injuriosas, coincidentes com marcas de produtos ou serviços de terceiros,
            denominações sociais, expressões publicitárias, nomes ou pseudônimos de personalidades públicas, de pessoas
            famosas ou registrados por terceiros, ou que não remetam à identidade do registrante e, em geral, contrários
            à lei, à ordem ou às exigências da moral e dos bons costumes comumente aceitos.</p>

        <p class="text">Os assinantes e/ou usuários que não cumprirem as determinações expressas no parágrafo anterior,
            poderão ter o seu “login” cancelado e bloqueado, sem aviso prévio, de acordo com seu critério exclusivo.</p>

        <p class="text">Depois de registrados, seus usuários estarão aptos a usufruir e desfrutar de benefícios de um
            usuário cadastrado em seu website. Toda e qualquer solução implementada em seu website estará disponível aos
            seus usuários, de acordo com as suas configurações de disponibilização.</p>

        <p class="text">A empresa <b>epigrafo.com</b>> possui diversos parceiros e patrocinadores que podem criar
            sorteios e
            promoções, oferecendo inúmeros bens e/ou serviços para nossos usuários.</p>

    </div>

    <div class="row div-row">
        <h5 class="text-h5">Parágrafo Segundo - Sobre Cookies e sua Utilidade</h5>

        <p class="text">Cookies são pequenos arquivos de texto enviados ao seu computador e ficam ali armazenados. Esses
            pequenos
            arquivos servem para reconhecer, acompanhar e armazenar a navegação do usuário na Internet.</p>

        <p class="text">O uso de cookies para acompanhar e armazenar informações possibilitará aos serviços
            implementados em seu
            website, oferecer um serviço mais personalizado (hiperlink para o texto explicativo sobre personalização),
            de acordo com as características e interesses dos usuários cadastrados, possibilitando, inclusive, a oferta
            de conteúdo e publicidade específicos para cada pessoa, beneficiando a experiência do usuário na Internet.
        </p>

        <p class="text">Em geral, os cookies são utilizados para proporcionar serviços diferenciados, identificando os
            hábitos de
            navegação de seus usuários. Eles também calculam a dimensão da audiência em seu website.</p>

        <p class="text">Com a medição de certos padrões de navegação e o mapeamento das áreas acessadas em seu website
            pelos seus
            usuário, é possível obtermos informações que serão utilizadas para as ofertas de conteúdo e/ou serviços cada
            vez mais personalizados, e ainda podem ser utilizadas para preencher previamente os formulários de coleta de
            dados existentes na Internet.</p>

        <p class="text">Os anunciantes, parceiros e/ou patrocinadores da empresa <b>epigrafo.com</b> poderão utilizar
            seus próprios
            cookies, os quais não são cobertos por esta política. Sugerimos que você consulte as políticas de
            privacidade dessas empresas.</p>

    </div>

    <div class="row div-row">

        <h5 class="text-h5">Parágrafo Terceiro – Sobre a Utilização das Informações</h5>

        <p class="text">As informações coletadas pelos serviços implementados em seu website serão utilizadas para
            personalizar o conteúdo e/ou serviços disponibilizados. Isso inclui serviços de personalização, comunicações
            interativas, compras on-line e outros tipos de serviços. Sabendo mais sobre seus usuários, o seu website
            pode fornecer conteúdo e serviços mais relevantes e proporcionar uma melhor experiência on-line.</p>

        <p class="text">As informações também poderão ser utilizadas para gerar dados estatísticos gerais, com
            finalidade
            informativa ou comercial, informações geográficas, demográficas, psicográficas e de perfil de uso. Tais
            informações poderão ser fornecidas a parceiros, patrocinadores, anunciantes e/ou outras empresas externas,
            sem que sejam revelados nomes ou outros dados de navegação.</p>

        <p class="text">As informações coletadas poderão ser utilizadas para direcionamento de campanhas publicitárias
            de uma forma
            geral, como por exemplo no caso em que um anunciante determina como foco de uma determinada campanha homens
            com mais de 25 anos. Nesse caso, veiculamos a propaganda aos usuários dentro dessa faixa etária utilizando
            banners e/ou e-mails promocionais. Os seus usuários ao se cadastrarem em seu website terão a opção de
            receber ou não em sua conta de e-mail ofertas e/ou propaganda, sendo que, a qualquer tempo, essa opção
            poderá ser alterada pelo usuário.</p>

        <p class="text">As informações pessoais individuais não serão comercializadas ou fornecidas a terceiros em
            nenhuma hipótese,
            salvo em estrito cumprimento de ordens judiciais ou procedimentos jurídicos similares. Todas as informações
            fornecidas a parceiros, patrocinadores, anunciantes e demais serão apenas de grupos de usuários (segmentos
            de mercado).</p>
    </div>

    <div class="row div-row">

        <h5 class="text-h5">Parágrafo Quarto – Sobre Quem Coleta as Informações</h5>

        <p class="text">Quando algum dos softwares implementados em seu website solicita informações pessoais a seus
            usuários, essas
            informações estarão sendo enviadas somente a websites administrados pelos servidores da empresa
            <b>epigrafo.com</b>. Alguns parceiros, patrocinadores, anunciantes e outros, que sejam acessados através dos
            serviços implementados em seu website, poderão solicitar informações financeiras e/ou pessoais dos seus
            usuários. Em tal situação, essas informações não estarão sendo enviadas à <b>epigrafo.com</b> nem ao seu
            web-site,
            e o Portal não terá qualquer responsabilidade pela utilização e manejo dessa informação, não sendo aplicada
            a presente política.</p>

        <p class="text">A <b>epigrafo.com</b> destaca que nas soluções implementadas em seu website podem existir links
            e/ou
            páginas que
            utilizem publicidade gráfica de outros parceiros e/ou empresas, como por exemplo, o Google Ads, que utiliza
            relatório de informações demográficas da respectiva plataforma de publicidade. Caso você tenha interesse em
            desativar tal publicidade gráfica, deverá enviar, para o e-mail saas@epigrafo.com, uma solicitação para
            inabilitação dessa função.</p>

        <p class="text">As promoções realizadas em seu website podem ser patrocinadas por outras empresas ou por uma
            parceria entre
            <b>epigrafo.com</b> e prestadores. Os dados coletados durante uma promoção podem ser compartilhados com o
            parceiro
            e/ou patrocinador. Se os dados forem compartilhados, você e seus usuários serão notificados antes da coleta
            ou transferência desses dados. Assim, poderá decidir não participar da promoção caso não deseje que seus
            dados sejam compartilhados.</p>

        <p class="text">Sempre que você acessar anunciantes ou outros Sites da Web através de links presentes no seu
            website através
            das soluções implementadas pela <b>epigrafo.com</b>, aqueles poderão coletar informações sobre seus
            usuários.
            Lembramos que as práticas relativas à privacidade desses parceiros e terceiros não são cobertos pela
            presente política e podem ser bem diferentes dos padrões de privacidade do seu website.</p>

    </div>

    <div class="row div-row">

        <h5 class="text-h5">Parágrafo Quinto – Sobre o Compartilhamento das Informações</h5>

        <p class="text">A <b>epigrafo.com</b> não tem como prática a divulgação de informações que possam identificar
            seus
            usuários, salvo
            quando autorizado por eles ou em circunstâncias previstas nesta presente Política. Em alguns casos, essa
            identificação será necessária, por exemplo: caso algum usuário adquira um determinado produto ou serviço ou
            ganhe um prêmio cuja entrega, para ser feita, necessite de suas informações pessoais. A identificação do seu
            usuário também pode ser pedida para que uma determinada transação comercial possa ser efetivada. A revelação
            dos dados dos seus usuários poderá ainda acontecer devido a exigências legais. Podemos revelar suas
            informações pessoais em algumas circunstâncias, listadas nos casos a seguir:</p>

        <h5 class="subtext-h5">5.1. Parceiros de Negócios e Patrocinadores</h5>

        <p class="subtext">O <b>epigrafo.com</b> através dos produtos implementados em seu website pode divulgar suas
            informações pessoais a alguns parceiros, patrocinadores, anunciantes e demais. Porém, nesses casos, você
            será consultado antes mesmo da coleta ou transferência de dados serem feitas. Os detalhes do uso
            compartilhado podem ser encontrados nos contratos de usos específicos e também nas regras das promoções. Os
            dados coletados durante uma promoção de <b>epigrafo.com</b> ou em seu website podem ser compartilhados com
            um
            determinado patrocinador. Se forem compartilhados dados que possam identificá-lo, você será informado antes
            da coleta ou transferência de dados.</p>

        <p class="subtext">Algumas promoções oferecem oportunidades para solicitação de informações adicionais dos
            patrocinadores.
            Solicitando mais informações, você dará ao <b>epigrafo.com</b> a permissão para transferir as informações
            pessoais
            dos seus usuários para o patrocinador, para que ele possa atender à sua solicitação.</p>

        <h5 class="subtext-h5">5.2. Canal de Compras:</h5>

        <p class="subtext">Se for implementado em seu website algum de nossos produtos de comercialização, seus usuários
            ao adquirirem um
            produto no canal de compras, as informações obtidas durante a sua visita às lojas e as informações que seus
            usuários fornecerem às mesmas, tais como o número do cartão de crédito e dados para contato, são
            automaticamente
            repassadas aos lojistas. Isso ocorre para permitir que as transações sejam efetuadas. Os lojistas que fazem
            parte do canal de compras do Sinari.com.br, permitidos para serem implementados em seu website, possuem
            práticas
            de privacidade e coleta de dados próprios. O <b>epigrafo.com</b> não se responsabiliza por essas políticas
            independentes. Para obter mais informações sobre o lojista, sua loja e suas políticas de privacidade,
            retorne à
            página inicial dessa loja e localize o link da respectiva política.</p>

        <h5 class="subtext-h5">5.3. Leilões:</h5>

        <p class="subtext">O <b>epigrafo.com</b> possui um serviço próprio de leilões que pode ser implementado em seu
            website, porém não assume
            a responsabilidade dos anunciantes e nem dos anunciados. Portanto, quaisquer responsabilidades em
            decorrência de
            informações que você venha a fornecer a sites que organizam esse tipo de evento, não estarão sujeitas à
            política
            de privacidade do <b>epigrafo.com</b>.</p>

        <h5 class="subtext-h5">5.4. Dados de Terceiros e Dados Agregados:</h5>

        <p class="subtext">Sob acordos de confidencialidade e demais instrumentos contratuais, o <b>epigrafo.com</b>
            pode
            comparar informações de
            usuários com dados de terceiros. Além de poder divulgar estatísticas agregadas para grupos de usuários do
            seu
            website (por exemplo, 45% dos seus usuários são do sexo feminino) com o objetivo de descrever os serviços
            que
            foram implementados em seu website. Esses dados podem ser úteis para futurows parceiros, anunciantes etc.,
            bem
            como outros objetivos legais.</p>

        <h5 class="subtext-h5">5.5. Outros:</h5>

        <p class="subtext">O <b>epigrafo.com</b> também pode divulgar informações sobre os usuários do seu website em
            casos
            especiais, nos quais
            a divulgação dessas informações é necessária para identificar, entrar em contato ou agir legalmente contra
            alguém que possa estar causando danos ou interferindo, intencional ou não intencionalmente, nos direitos ou
            na
            propriedade de <b>epigrafo.com</b> e/ou de seus clientes, bem como de qualquer pessoa que seja prejudicada
            por
            tais
            atividades. O <b>epigrafo.com</b> pode divulgar informações sobre os usuários do seu website quando exigido
            por
            lei e
            com objetivos administrativos ou outros que considerarmos necessários para manter e aprimorar nossos
            produtos e
            serviços.</p>

    </div>

    <div class="row div-row">

        <h5 class="text-h5">Parágrafo Sexto – Sobre as Opções a Respeito da coleta, Uso e Distribuição das Informações
        </h5>

        <p class="text">Se os seus usuários, ao se registrarem, sinalizarem que estão interessados em receber ofertas
            e/ou
            informações provenientes de seu website, da <b>epigrafo.com</b> ou de nossos parceiros, poderemos,
            ocasionalmente,
            enviar-lhes mensagens, via e-mail, sobre produtos e serviços que possam interessá-los. Somente a empresa
            <b>epigrafo.com</b>, seu website ou agentes que trabalhem em nome da <b>epigrafo.com</b>, sob acordos de
            confidencialidade, enviarão a seus usuários essas comunicações e somente se os mesmos indicaram que não tem
            objeção contra essas ofertas.</p>

        <p class="text">Para alguns tipos de promoções, solicitaremos a seus usuários o endereço de e-mail para
            atualizá-los sobre
            suas pontuações, suas participações e status nas premiações. Seus usuários, ao participarem desse tipo de
            promoção, caso forneçam seus e-mails, receberão automaticamente mensagens relacionadas ao assunto, que podem
            incluir anúncios direcionados. Se uma determinada promoção não oferecer uma opção de não recebimento de
            e-mail, seus usuários poderão optar por não receber e-mails, mas deixarão de participar dessa promoção.</p>

        <p class="text">Seus usuários também têm opções em relação aos cookies. Modificando as preferências de
            navegação, os
            usuários têm a opção de aceitar todos os cookies, ser notificado quando um cookie for enviado ou rejeitar
            todos os cookies. Se seus usuários optarem por rejeitar todos os cookies, não conseguirão usar alguns dos
            serviços implementados no seu website, que requerem registro para participação. Também não poderão usufruir
            de todos os benefícios e facilidades de uma experiência personalizada na Internet.</p>

        <p class="text">A <b>epigrafo.com</b> não comercializa informações dos seus usuários. Na ocasião da coleta ou
            transferência dos
            dados, será notificado ao seu website e aos seus usuários, caso as informações sejam compartilhadas com
            terceiros. Além disso, seus usuários sempre terão a opção de não permitir a transferência desses dados. Se
            não desejarem que tais dados sejam compartilhados, poderão optar por não usarem um determinado serviço ou
            não participarem de certas promoções ou concursos.</p>

    </div>

    <div class="row dir-row">

        <h5 class="text-h5">Parágrafo Sétimo – Sobre as Tratativas das Informações, Relativas a Finanças Pessoais e
            Saúde</h5>

        <p class="text">As informações dos usuários, relativas a finanças e saúde, serão mantidas na empresa
            <b>epigrafo.com</b> de forma
            confidencial e não serão comercializadas.</p>

        <p class="text">O perfil dos seus usuários possibilita a oferta de conteúdos personalizados. Porém, como ocorre
            com todas as
            informações que podem identificá-los, não serão disponibilizadas para terceiros sem a permissão dos mesmos.
            Quaisquer estatísticas sobre seus usuários, que possam ser úteis a prováveis anunciantes ou parceiros, com
            relação ao uso de produtos ou serviços financeiros em seu website através da <b>epigrafo.com</b>, serão fornecidas
            somente em blocos e não demonstrarão nenhum dado que permita a identificação pessoal de quaisquer usuários.
        </p>

        <p class="text">Existem locais nos serviços implementados em seu website onde podem se transmitir informações
            sobre cartão
            de crédito para conclusão de transações de compra ou com o objetivo de verificação. Tais informações, se
            necessárias, serão fornecidas no formato criptografado. É utilizada a criptografia padrão da indústria, SSL
            (Secure Sockets Layer, camada de soquetes de segurança).</p>
    </div>

    <div class="row">
        <img class="img" src="./assets/img/ass-sinair.png" alt="">
    </div>

</div>

<app-curva-footer></app-curva-footer>
