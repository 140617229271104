import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Estratégias empreendedoras',
            title: 'Por que estudar as estratégias antes de empreender seu projeto?',
            paragraphText: 'O estudo estratégico do seu projeto permite alcançar os objetivos em menor tempo, mais precisão nas ações, menos prejuízo nos gastos e investimentos e êxito no sucesso do empreendimento.'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-rocket',
            title: 'Evolução Planejada',
            paragraphText: 'Ao contrário do que se pensa, você pode planejar a evolução do seu empreendimento, avaliando diariamente o desempenho da equipe.',
            link: 'services-details',
            linkText: 'Mais detalhes'
        },
        {
            icon: 'flaticon-laptop',
            title: 'Soluções em Equipe',
            paragraphText: 'Ferramentas online para você avaliar o andamento de seu empreendimento, seja através da comunidade ou pelos integrantes da sua equipe.',
            link: 'services-details',
            linkText: 'Mais detalhes'
        },
        {
            icon: 'flaticon-money',
            title: 'Planejamento Estratégico',
            paragraphText: 'Planeje com precisão o que será feito, quem irá fazer, quanto será destinado de recurso, qual a precisão esperada do empenho e os resultados obtidos.',
            link: 'services-details',
            linkText: 'Mais detalhes'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}