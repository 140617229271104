import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solucoes-sob-medida',
    templateUrl: './solucoes-sob-medida.component.html',
    styleUrls: ['./solucoes-sob-medida.component.scss']
})
export class SolucoesSobMedidaComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Soluções sob medida",
            title: 'Soluções equivalentes ao tamanho do seu projeto',
            paragraphText: 'Os serviços virtuais oferecidos pela Epigrafo são planejados de acordo com o tamanho do seu projeto, nosso objetivo e crescer com você.'
        }
    ]
    singleProcessBox: processBoxContent[] = [
        {
            img: 'assets/img/process/img1.png',
            title: 'Tudo começa aqui',
            paragraphText: 'Conheça as soluções virtuais oferecidas pela Epigrafo, comece seu empreendimento sem custo e utilize e sem restrições porém com limites definidos.',
            number: '1'
        },
        {
            img: 'assets/img/process/img2.png',
            title: 'Comunidade Empreendedora',
            paragraphText: 'Crie sua comunidade e envolva em seus projetos, convide seus amigos ou obtenha consultoria de profissionais empreendedores.',
            number: '2'
        },
        {
            img: 'assets/img/process/img3.png',
            title: 'Profissional Empreendedor',
            paragraphText: 'Possibilita aos profissionais qualificados gerir seus empreendimento e oferecer consultorias na gestão e administração utilizando os serviços da Epigrafo.',
            number: '3'
        },
        {
            img: 'assets/img/process/img4.png',
            title: 'Empresa Empreendedora',
            paragraphText: 'Tenha a opção de criar sua comunidade ou cadastrar os colaboradores e definir o organograma institucional de seu empreendimento.',
            number: '4'
        },
        {
            img: 'assets/img/process/img5.png',
            title: 'Coorporativo',
            paragraphText: 'Soluções personalizadas, definidas, dedicadas e privadas com hardwares exclusivo possibilitando interação entre matriz e filial.',
            number: '5'
        },
        {
            img: 'assets/img/process/img6.png',
            title: 'Governamental',
            paragraphText: 'Não importa o tamanho da instituição pública, os serviços virtuais da Epigrafo se moldam às necessidades apresentadas fornecendo relatórios distribuídos.',
            number: '6'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class processBoxContent {
    img : string;
    title : string;
    paragraphText : string;
    number : string;
}