<app-swot-title></app-swot-title>

<!-- <app-solucoes></app-solucoes>

<app-solucoes-estrategicas></app-solucoes-estrategicas>

<app-estrategias></app-estrategias>

<section class="process-area pt-100 pb-70" style="background-color: #482e0729;">
    <app-solucoes-sob-medida></app-solucoes-sob-medida>
</section>

<app-quem-usa></app-quem-usa> -->

<!-- <app-homeone-case-studies></app-homeone-case-studies> -->

<!-- <section class="scientist-area bg-color pt-100 pb-70">
    <app-homeone-scientist></app-homeone-scientist>
</section> -->

<!-- <app-homeone-testimonials></app-homeone-testimonials> -->

<!-- <app-homeone-partner></app-homeone-partner> -->

<!-- <app-homeone-blog></app-homeone-blog> -->

<!-- <app-project-start></app-project-start> -->