import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-about',
    templateUrl: './homeone-about.component.html',
    styleUrls: ['./homeone-about.component.scss']
})
export class HomeoneAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/about/img1.png'
        }
    ]
    aboutContent: Content[] = [
        {
            subTitle: 'Soluções estratégicas',
            title: 'Soluções integradas para você e sua equipe empreender',
            paragraphText1: 'Não se limite a planilhas ou relatórios, controle o andamento de seus projetos, avalie os procedimentos e faça ajustes em tempo real.',
            paragraphText2: 'Cuide da saúde do seu projeto, avalie a eficácia dos procedimentos, nós zelamos do bom funcionamento e comunicação de seus projetos.',
            defaultBtnIcon: 'flaticon-right',
            defaultBtnText: 'Mais detalhes',
            defaultBtnLink: 'about-us'
        }
    ]
    featuresList: List[] = [
        {
            icon: 'assets/img/icon1.png',
            title: '100%',
            subTitle: 'online'
        },
        {
            icon: 'assets/img/icon2.png',
            title: 'Ilimitados',
            subTitle: 'colaboradores'
        },
        {
            icon: 'assets/img/icon3.png',
            title: 'Serviços',
            subTitle: 'Integrados'
        },
        {
            icon: 'assets/img/icon4.png',
            title: 'Ilimitados',
            subTitle: 'Projetos'
        }
    ]

}
class Image {
    img : string;
}
class Content {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    defaultBtnIcon : string;
    defaultBtnText : string;
    defaultBtnLink : string;
}
class List {
    icon : string;
    title : string;
    subTitle : string;
}