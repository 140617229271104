import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-estrategias',
    templateUrl: './estrategias.component.html',
    styleUrls: ['./estrategias.component.scss']
})
export class EstrategiasComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Estratégias',
            title: 'Antigas soluções inovadas para o mundo digital',
            paragraphText: 'A décadas estas soluções estão disponíveis para você e sua equipe empreender, agora trouxemos estas metodologias para o mundo digital e 100% online.'
        }
    ]
    singleServicesBox: ServicesBoxContent[] = [
        {
            icon: 'assets/img/services/icon1.png',
            title: 'Análise SWOT',
            paragraphText: 'Avalie as forças e as fraquezas do seu negocio, debata em equipe as ameaças e amplie as oportunidades do seu empreendimento.',
            link: 'analiseswot',
            linkText: 'Mais detalhes',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon3.png',
            title: 'Modelo CANVAS',
            paragraphText: 'Construa o valor de mercado do seu empreendimento, estude os fluxos, avalie e defina o modelo que irá atuar no mercado segmentado.',
            link: 'emproducao',
            linkText: 'Em produção',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon5.png',
            title: 'Ciclo PDCA',
            paragraphText: 'Programe suas atividades com bom planejamento, faça com eficácia, cheque constantemente e tome as melhores decisões em suas ações.',
            link: 'emproducao',
            linkText: 'Em produção',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon4.png',
            title: 'Modelo SMART',
            paragraphText: 'Estude suas metas e não tenha resoluções vagas, construa trajetórias com objetivos definidos e claros obtenha uma estimativa para atingir as metas.',
            link: 'emproducao',
            linkText: 'Em produção',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon2.png',
            title: 'Matriz BCG',
            paragraphText: 'Gerencie seu portfólio de produtos do seu empreendimento com eficácia e saiba qual a melhor estratégia de investimento dos recursos estimados e obtidos.',
            link: 'emproducao',
            linkText: 'Em produção',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon6.png',
            title: 'Matriz GUT',
            paragraphText: 'Obtenha as informações necessárias que auxilie nas resoluções de problemas, não decida no escuto, tenha o controle na resolução de problemas.',
            link: 'emproducao',
            linkText: 'Em produção',
            linkIcon: 'flaticon-right'
        }
    ]
    loadMoreBtn: loadMore[] = [
        {
            link: 'services',
            linkText: 'Todas estratégias disponíveis',
            linkIcon: 'flaticon-right'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class ServicesBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
    linkIcon : string;
}
class loadMore {
    link : string;
    linkText : string;
    linkIcon : string;
}