<div class="navbar-area">
    <div class="wilo-responsive-nav">
        <div class="container">
            <div class="wilo-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <!-- <img src="assets/img/logo.png" alt="logo"> -->
                        <img src="assets/img/logo-epg.png" alt="logo" style="height:50px;">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="wilo-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo-epg.png" alt="logo" style="height:50px;">
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/epigrafo" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Epigrafo <i
                                class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/sobreaepigrafo" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Evolução planejada </a></li>

                                <li class="nav-item"><a routerLink="/nossosobjetivos" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Soluções em equipe</a></li>

                                <li class="nav-item"><a routerLink="/metasparafuturo" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Planejamento estratégico</a></li>
<!-- 
                                <li class="nav-item"><a routerLink="/sobreaepigrafo" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Sobre a
                                        Epigrafo</a></li>

                                <li class="nav-item"><a routerLink="/nossosobjetivos" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Nossos
                                        objetivos</a></li>

                                <li class="nav-item"><a routerLink="/metasparafuturo" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Metas para o
                                        futuro</a></li>
                                         -->
                            </ul>
                        </li>

                        <!-- <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About Us</a></li> -->

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Estratégias <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/analiseswot" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Análise
                                        SWOT</a></li>

                                <li class="nav-item"><a routerLink="/services-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Ciclo
                                        PDCA</a></li>

                                <li class="nav-item"><a routerLink="/services-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Modelo
                                        CANVAS</a></li>

                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Curva ABC</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Modelo
                                        SMART</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Plano de
                                        ação 5W2H</a></li>

                                <li class="nav-item"><a routerLink="/home-four" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Desafio 52
                                        semanas</a></li>

                                <li class="nav-item"><a routerLink="/case-studies-3-columns" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Matriz
                                        BCG</a></li>

                                <li class="nav-item"><a routerLink="/case-studies-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Matriz
                                        GUT</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Pacotes & Serviços <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/home-three" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tudo começa
                                        aqui</a></li>

                                <li class="nav-item"><a routerLink="/case-studies-3-columns" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Comunidade
                                        Empreendedora</a></li>
                                <!-- 
                                <li class="nav-item"><a routerLink="/case-studies-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Profissional
                                        Empreendedor</a></li>

                                <li class="nav-item"><a routerLink="/case-studies-3-columns" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Empresa
                                        Empreendedora</a></li>

                                <li class="nav-item"><a routerLink="/case-studies-details" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Coorporativo</a></li>

                                <li class="nav-item"><a routerLink="/case-studies-details" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Governamental</a></li>
                                         -->
                            </ul>
                        </li>

                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Blog <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog
                                        Details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Pages <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses <i
                                            class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Courses</a></li>

                                        <li class="nav-item"><a routerLink="/courses-details" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Courses Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Events <i
                                            class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/events" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Events</a></li>

                                        <li class="nav-item"><a routerLink="/events-details" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Events Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Fale Conosco</a></li>

                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <!-- <div class="option-item">
                            <form class="search-box">
                                <input type="text" class="input-search" placeholder="Search for anything">
                                <button type="submit"><i class="flaticon-loupe"></i></button>
                            </form>
                        </div> -->

                        <div class="option-item">
                            <a href="https://br.epigrafo.com" target="_blank" class="default-btn"><i
                                    class="flaticon-right"></i>Acessar<span></span></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <form class="search-box">
                                <input type="text" class="input-search" placeholder="Search for anything">
                                <button type="submit"><i class="flaticon-loupe"></i></button>
                            </form>
                        </div>

                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"><i
                                    class="flaticon-right"></i>Acessar<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>