<footer class="{{footerClass}}">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <!-- <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="logo"></a> -->
                    <a routerLink="/" class="logo"><img src="assets/img/logo-epg.png" alt="logo"
                            style="height:50px;"></a>
                    <p>Soluções inteligentes ao seu alcance. Nosso principal objetivo e atender suas necessidades.
                        Nossa meta e mantermos a saúde do seu empreendimento.</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-1 col-md-6 col-sm-6">&nbsp;</div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Conheça</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="epigrafo">Home</a></li>
                        <li><a routerLink="/sobreaepigrafo">Evolução planejada</a></li>
                        <li><a routerLink="/nossosobjetivos">Soluções em equipe</a></li>
                        <li><a routerLink="/metasparafuturo">Planejamento estratégico</a></li>
<!-- 
                        <li><a routerLink="/sobreaepigrafo">Sobre a Epigrafo</a></li>
                        <li><a routerLink="/nossosobjetivos">Nossos objetivos</a></li>
                        <li><a routerLink="/metasparafuturo">Metas para o futuro</a></li>
                         -->
                        <li><a routerLink="/contact">Fale conosco</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Estratégias</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/team">Soluções a seu alcance</a></li>
                    </ul>
                </div>
                <div class="single-footer-widget">
                    <h3>Pacotes & Serviços</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/team">Tudo começa aqui</a></li>
                        <li><a routerLink="/services">Comunidade empreendedora</a></li>
<!-- 
                        <li><a routerLink="/">Profissional empreendedor</a></li>
                        <li><a routerLink="/services-details">Empresa empreendedora</a></li>
                        <li><a routerLink="/services-details">Coorporativo</a></li>
                        <li><a routerLink="/services-details">Governamental</a></li>
                         -->
                    </ul>
                </div>
            </div>

        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class='bx bx-copyright'></i>2020 <strong>Epigrafo</strong> Soluções & Serviços Virtuais.</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/politicadeprivacidade">Política de privacidade</a></li>
                        <li><a routerLink="/condicoesdeuso">Termos e condições de uso.</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-map"><img src="assets/img/footer-map.png" alt="image"></div>
</footer>

<div class="go-top"><i class="flaticon-up"></i></div>