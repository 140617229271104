<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">epigrafo</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>


<div class="container">

    <div class="row div-row">

        <p class="text">Este instrumento, bem como outros termos, contratos, políticas, regras, exigências, limitações,
            sinalizações, notas, notificações e demais condições disponíveis, referentes aos serviços oferecidos pela
            empresa <b>epigrafo.com</b>, inclusive termos de uso de produtos específicos e a Política Privacidade, doravante
            denominados "Termos Gerais", são passíveis de alterações e deverão ser consideradas as versões vigentes à
            época de utilização do Portal <b>epigrafo.com</b>.</p>

        <p class="text">A Política de Uso e Segurança regulamenta a disponibilização, utilização e acesso pelos seus
            usuários nas aplicações implementadas aos Conteúdos em seu, conforme definições abaixo, bem como limitam a
            responsabilidade da <b>epigrafo.com</b>, empresa localizada na Rua T-30, nº 1675, Setor Bueno, no Estado de Goiás,
            Cidade de Goiânia, Brasil, CEP: 74215060, inscrita no Cadastro Nacional da Pessoa Jurídica do Ministério da
            Fazenda Brasileiro (CNPJ/MF) sob o nº 13.645.688/0009/13.</p>

    </div>

    <div class="row div-row">

        <h5 class="text-h5">Parágrafo Primeiro - Direitos Sobre o Conteúdo Disponibilizado</h5>

        <p class="text">Todos os produtos contidos no Portal <b>epigrafo.com</b> e nos aplicativos disponibilizados pela
            empresa <b>epigrafo.com</b>, incluindo, mas sem se limitar, a textos, vídeos, formatos, ilustrações, animações,
            gráficos, fotografias, marcas, patentes, fontes, grafismo, esquemas de cores, layout, trade-dress,
            identidade visual, experiência de navegação, designs, aplicativos, interfaces de aplicativos, softwares,
            fragmentos de softwares, doravante designados, coletivamente ou em relação a qualquer parcela destes, como
            “Conteúdo”, são protegidos pela legislação brasileira, em especial pela Lei de Direitos Autorais (Lei
            Federal 9.610/98) e Lei de Propriedade Industrial (Lei Federal 9.279/96).</p>

        <p class="text">O uso autorizado dos Conteúdos, aplicações e apps na modalidade SaaS Software a as Service
            (Software como Serviço), consiste, unicamente, em uma licença de uso para o seu website, sem caráter de
            exclusividade, sendo a mesma intransferível, revogável e limitada estritamente para visualização do Conteúdo
            nos meios disponibilizados e autorizados pela empresa <b>epigrafo.com</b>, sempre em âmbito pessoal e privado, com
            ou sem fins comerciais. Nenhum outro direito adicional é concedido a seu website, salvo se feito de forma
            expressa e por escrito pela <b>epigrafo.com</b> ou pelo legítimo titular dos direitos sobre o Conteúdo.</p>

        <p class="text">Lembramos que qualquer acesso e/ou utilização que não os expressamente autorizados ficam
            vedados. São vedadas, exemplificativamente, as seguintes práticas, seja por meio de ação, dispositivo,
            mecanismo, software ou de qualquer forma e para qualquer fim:</p>

        <p class="subtext">i. Remover, alterar, interferir, degradar, desativar, evitar ou de qualquer forma modificar
            marca d’água, copyright, símbolo, marca ou qualquer outro sinal indicativo de titularidade ou para outro fim
            inserido no Conteúdo, ou quaisquer direitos e/ou mecanismos de proteção associados ao Conteúdo, bem como
            filtros e controle de acesso, inclusive se baseados em território (geoblocking) ou faixa etária;</p>

        <p class="subtext">ii. Copiar no todo ou em parte, fazer download, executar engenharia reversa, “descompilar”,
            “recompilar”, “compilar”, criptografar, “desencriptografar”, desmembrar, capturar, reproduzir, arquivar,
            distribuir, fazer upload, publicar, modificar, traduzir, exibir, transmitir, distribuir, disponibilizar,
            utilizar métodos de data mining, coleta ou extração de dados, ou embeddar o Conteúdo;</p>

        <p class="subtext">iii. Utilizar meta-tags para extração dos Conteúdos, reorganizar, armazenar, captar, exibir,
            retransmitir, tornar disponível ou incorporar o Conteúdo, inclusive frames, recaps e screenshots em qualquer
            meio;</p>

        <p class="subtext">iv. 1.4. Comercializar, distribuir gratuita ou onerosamente, anunciar ou desenvolver um
            negócio, direta ou indiretamente, a partir do Conteúdo;</p>

        <p class="subtext">v. Utilizar o Conteúdo de qualquer forma para a criação de obras derivadas ou nele baseadas,
            tais como aplicativos, websites, montagens, mash-ups, vídeos e materiais de marketing ou merchandising,
            entre outros;</p>

        <p class="subtext">vi. Modificar, remover, interferir, tentar alterar qualquer parte do Conteúdo, em especial os
            players de vídeo, ou qualquer tecnologia por trás dos mesmos. Essa restrição também se aplica a qualquer
            alteração, interferência ou ação que de qualquer forma possibilite, ainda que de forma privada, a
            visualização do Conteúdo sem que sejam exibidos e/ou utilizados os aplicativos e players de vídeo
            disponibilizados pela empresa <b>epigrafo.com</b> em seu website e todos os elementos relacionados aos apps
            implementados em seu website denominados SaaS (tais como interface, marcas registradas e eventuais anúncios)
            ou sem que sejam acessíveis todas as funcionalidades, anúncios e interfaces associados aos Conteúdos da
            empresa <b>epigrafo.com</b> em seu website;</p>

        <p class="subtext">vii. Associar ou gerar qualquer impressão, de fato ou em potência, de associação de nomes,
            produtos, pessoas, empresas, terceiros ou de qualquer elemento com os Conteúdos e/ou com os apps
            implementados em seu website; </p>

        <p class="subtext">viii. Utilizar de qualquer interface (API) da própria empresa <b>epigrafo.com</b> ou de terceiros
            para qualquer fim, inclusive para extração, distribuição, execução e/ou disponibilização dos Conteúdos.</p>

        <p class="subtext">Parágrafo Único: Excepcionalmente, a empresa <b>epigrafo.com</b> autoriza a indexação automatizada
            de textos presentes no Conteúdo, desde que estejam disponíveis em áreas de acesso irrestrito ao seu website
            utilizando-se dos apps implementados e que não contenham qualquer ressalva à indexação, por crawlers de
            ferramentas de buscas de utilização pública e gratuita exclusivamente para apresentação de hyperlinks em
            resultados gerais de busca que apontem para os Conteúdos, desde que isto não importe em violação dos itens
            “i” a “viii”, em especial do item “iii”.</p>

    </div>

    <div class="row div-row">

        <h5 class="text-h5">Parágrafo Segundo - Isenção de Responsabilidade</h5>

        <p class="text">Hyperlinks externos no seu website ou em sites/ambientes não são endossados pela empresa
            <b>epigrafo.com</b>, não estando vinculados às informações, produtos ou serviços ali contido.</p>

        <p class="text">A empresa <b>epigrafo.com</b> não concede qualquer garantia relacionada à disponibilidade,
            continuidade de funcionamento ou infalibilidade do seu website e/ou Conteúdos, nem que os mesmos serão úteis
            para a realização de qualquer atividade em particular. Ainda, consideradas as características do meio
            internet, a <b>epigrafo.com</b> não garante a segurança e privacidade do Conteúdo fora do domínio <b>epigrafo.com</b> ou
            do domínio direcionado aos nossos servidores na publicação dos serviços, nem que o mesmo será ininterrupto,
            livre de vírus e/ou outros problemas, erros e ataques. E, em particular, não garante que terceiros não
            autorizados não possam acessar e, eventualmente, interceptar, eliminar, alterar, modificar ou manipular de
            qualquer modo os dados presentes e/ou transmitidos a seus servidores.</p>

    </div>

    <div class="row div-row">

        <h5 class="text-h5">Parágrafo Terceiro - Segurança e Fluxo Normal de Comunicações entre Servidores</h5>

        <p class="text">Fica expressamente vedada qualquer prática que possa prejudicar a imagem da empresa
            <b>epigrafo.com</b> ou violar direitos desta sobre o Conteúdo, danificar seu patrimônio, danificar ou de qualquer
            forma interferir no fluxo normal de comunicações com seus servidores, na segurança, inviolabilidade e
            privacidade dos dados lá armazenados e transmitidos, incluindo, mas não se limitando, àquelas que importem,
            possam importar ou favoreçam:</p>

        <p class="subtext">i. Interceptação ou monitoramento do tráfego de dados a partir ou para os servidores da
            <b>epigrafo.com</b>;</p>

        <p class="subtext">ii. Fraudar, mascarar ou alterar a origem de comunicações incluindo, mas sem se limitar a
            alteração de cabeçalhos de e-mails, origem de arquivos, origem da requisição de dados, etc.;</p>

        <p class="subtext">iii. Degradar, fazer qualquer uso que não expressamente autorizado ou sobrecarregar com
            qualquer finalidade os servidores da <b>epigrafo.com</b>;</p>

        <p class="subtext">iv. Acessar qualquer dado disponibilizado nos servidores da empresa <b>epigrafo.com</b> de forma
            ilegítima, falsificando credenciais, utilizando-se de credenciais de terceiros, etc.;</p>

        <p class="subtext">v. Quebrar, bypassar, danificar, adulterar ou degradar qualquer parte ou mecanismo de
            segurança da <b>epigrafo.com</b> e seus servidores.</p>

    </div>

    <div class="row div-row">

        <h5 class="text-h5">Parágrafo Quarto - Aceitação</h5>

        <p class="text">Ao se cadastrar e/ou utilizar o Conteúdo de qualquer forma, você expressa sua aceitação plena e
            sem reservas aos Termos Gerais, tais como vigentes naquele momento, para todos os fins de direito. Assim, a
            leitura atenta dos Termos Gerais pelos seus usuários, em cada uma das ocasiões em que se propuser a se
            cadastrar e/ou utilizar o Conteúdo, é recomendável.</p>

        <p class="text">Caso não haja concordância de alguns dos termos e regras aqui previstos, não deverá ser
            realizado o cadastro e/ou a utilização do Conteúdo da <b>epigrafo.com</b>.</p>

    </div>

    <div class="row div-row">

        <h5 class="text-h5">Parágrafo Quinto - Suspensão, Bloqueio e Exclusão</h5>

        <p class="text">Com o objetivo de otimizar a disponibilização do Conteúdo e preservar a segurança da sua
            website, a empresa <b>epigrafo.com</b> poderá restringir, limitar e realizar a suspensão ou bloqueios, totais ou
            parciais, de disponibilização e acesso ao Conteúdo, sem necessidade de prévio aviso.</p>

    </div>

    <div class="row div-row">

        <h5 class="text-h5">Parágrafo Sexto - Condições Gerais</h5>

        <p class="text">No caso de haver conflitos entre as regras dos instrumentos listados na definição dos Termos
            Gerais constantes no caput deste instrumento, deverão prevalecer as regras e condições disponibilizadas, por
            escrito, pela empresa <b>epigrafo.com</b>.</p>

        <p class="text">O não exercício pela empresa <b>epigrafo.com</b> de quaisquer direitos e/ou faculdades que lhe sejam
            conferidos
            por estes Termos Gerais ou pela Legislação Brasileira, bem como a eventual tolerância contra infrações a
            estes Termos Gerais ou a seus direitos, não implicará na renúncia aos seus direitos, novação ou alteração de
            itens e cláusulas deste instrumento, podendo ainda exercer seus direitos a qualquer momento.</p>

        <p class="text">Estes Termos Gerais, bem como a relação decorrente das ações aqui compreendidas, assim como
            qualquer disputa
            que surja em virtude disto, serão reguladas exclusivamente pela Legislação Brasileira.</p>

        <p class="text">Fica eleito o Foro Central da Comarca da Capital do Estado do Goiás para dirimir quaisquer
            questões oriundas
            da utilização dos Conteúdos e/ou violação destes Termos Gerais, renunciando as partes a qualquer outro, por
            mais privilegiado que seja ou venha a ser.</p>

        <p class="text">Caso você considere que existem fatos, circunstâncias ou suspeitas de qualquer uso inadequado
            dos Conteúdos
            ou qualquer questão de segurança relacionada aos serviços que oferecemos na modalidade SaaS para
            implementação em seu website e, em particular, que representem violação de direito autoral ou de propriedade
            intelectual ou outros direitos, pedimos que envie uma comunicação à <b>epigrafo.com</b>, por meio do e-mail
            saas@epigrafo.com, contendo, se possível, seus dados pessoais (nome, endereço e número de telefone), bem
            como especificação do evento verificado, em particular, quando se tratar de suposta violação de direitos
            autorais, informar a indicação precisa e completa dos conteúdos protegidos e supostamente infringidos com as
            respectivas URLs).</p>

    </div>

    <div class="row">
        <img class="img" src="./assets/img/ass-sinair.png" alt="">
    </div>

</div>

<app-curva-footer></app-curva-footer>