import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-epigrafo',
  templateUrl: './epigrafo.component.html',
  styleUrls: ['./epigrafo.component.scss']
})
export class EpigrafoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
